import { documentsApi } from 'api/documentsApi';
import { actionCreator } from 'api/utils';
import { PdfReader,
  Spinner } from 'components';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Modal } from 'components/InteractiveUIControls/Modal/Modal';
import environment from 'config/environment';
import { ModalActivationContext } from 'contexts';
import {
  type FC,
  type SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { useLocation,
  useNavigate } from 'react-router-dom';
import { isMobile } from 'services/mobile';
import { type AppDispatch } from 'store';
import { filesActionTypes } from 'store/actions';
import { markDocumentsAsSeen } from 'store/slices';
import {
  type DocumentDetailsFromStorage,
  type LoginAsFromStorage,
  removeItemsFromStorage} from 'types';
import { getItemsFromStorage } from 'types';

export type IndividualFileStore = {
  fileByIdLoading: boolean,
  viewingFileByIdLoading: boolean,
};

export type FilesStore = {
  files: IndividualFileStore,
};

export const FileViewModal: FC<{docId: string | null, fileName: string | null,}> =
  ({docId,
    fileName: initialFileName}: {docId: string | null, fileName: string | null,}) => {
    const navigate = useNavigate();
    const {pathname,
      search} = useLocation();
    const mobile = isMobile();
    const dispatch: AppDispatch = useDispatch();
    const queryParameters = new URLSearchParams(search);
    const { fileViewId } = Object.fromEntries(queryParameters.entries());
    const {
      fileByIdLoading,
    } = useSelector<FilesStore>((state) => state.files) as IndividualFileStore;
    const {loginAs} = getItemsFromStorage<LoginAsFromStorage>([
      'loginAs',
    ], sessionStorage);
    const {
      categoryId,
      documentGroupId,
      documentId,
      isNewDocument,
    } = getItemsFromStorage<DocumentDetailsFromStorage>([
      'categoryId',
      'documentGroupId',
      'documentGroupName',
      'documentId',
      'isNewDocument',
    ], sessionStorage);
    const { toggleFileViewModal } = useContext(ModalActivationContext);

    const [
      getFileUrl,
      setGetFileUrl,
    ] = useState<string | null>(null);
    const [
      fileName,
      setFileName,
    ] = useState<string | null>(initialFileName);

    const getFileNameFromHeaders = useCallback(async (fileUrl: string) => {
      try {
        const response = await fetch(fileUrl, {
          credentials: 'include',
          method: 'HEAD',
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="?(.+)/iu);
          if (fileNameMatch?.[1]) {
            setFileName(fileNameMatch[1]);
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching file name:', error);
      }
    }, []);

    useEffect(() => {
      (async () => {
        if (!docId) {
          return;
        }

        dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, true));

        if ((!loginAs || loginAs === 'false') && docId) {
          await documentsApi.viewDocuments({documentIds: [
            docId,
          ]});
        }

        const fileUrl = `${environment.apiUrl}/api/v2/files/${docId}`;
        setGetFileUrl(fileUrl);

        if (!fileName) {
          await getFileNameFromHeaders(fileUrl);
        }

        if (categoryId && documentGroupId && documentId && isNewDocument === 'true') {
          dispatch(markDocumentsAsSeen({
            categoryId,
            documentGroupId,
            documentIds: [
              documentId,
            ],
          }));
        }

        dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, false));
      })();
    }, [
      fileName,
      categoryId,
      dispatch,
      docId,
      documentGroupId,
      documentId,
      isNewDocument,
      loginAs,
      getFileNameFromHeaders,
    ]);

    const closeFileViewModal = () => {
      toggleFileViewModal(false);
      removeItemsFromStorage<DocumentDetailsFromStorage>([
        'categoryId',
        'documentGroupId',
        'documentGroupName',
        'documentId',
        'isNewDocument',
      ], sessionStorage);
      setGetFileUrl(null);
      if (fileViewId) {
        navigate(pathname);
      }
    };

    const downloadClick = async (event: SyntheticEvent | undefined) => {
      event?.stopPropagation();
      dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, true));
      if (isMobile()) {
        await documentsApi.downloadDocumentMobile(fileName as string, docId as string);
      } else {
        await documentsApi.downloadDocument(fileName as string, docId as string);
      }

      dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, false));
    };

    const titleForModal = () => <div className='flex w-full items-center justify-between pr-6'>
      <p className='m-0 whitespace-nowrap text-title-4 font-medium'>{fileName}</p>
      <Button
        className='w-max'
        data-test='download-file'
        icon='left'
        iconColor='none'
        iconid='download-3f'
        isLoading={fileByIdLoading}
        onClick={(event) => downloadClick(event)}
        size='medium'
        tooltipWrapperClass='!w-fit'
        type='grey'
      > Download </Button>
    </div>;

    const titleForModalMobile = () => <div className='flex w-full items-center'>
      <Button
        className='!h-2'
        data-test='download-file'
        icon='only'
        iconColor='none'
        iconid='download-3f'
        isLoading={fileByIdLoading}
        onClick={(event) => downloadClick(event)}
        size='large'
        tooltipWrapperClass='!w-fit'
        type='transparent'
      > </Button>
      <p className='m-0 truncate text-title-4 font-medium'>{fileName}</p>
    </div>;

    return <Modal onToggle={closeFileViewModal} showCloseIcon >
      <div className='py-[0.625rem] pr-12 dt:px-1.25 dt:pt-[0.675rem]'>
        {mobile ? titleForModalMobile() : titleForModal() || <p>PDF Viewer</p>}
      </div>
      <div className='h-[calc(100vh-3.5rem)] dt:h-[85vh] dt:w-[80vw] dt:p-1.25'>
        {getFileUrl ?
          <PdfReader url={getFileUrl} /> :
          <Spinner />}
      </div>
    </Modal>;
  };
